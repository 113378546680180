import React, { ChangeEvent, useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import {
  Text,
  Box,
  Container,
  Flex,
  Heading,
  Badge,
  Stack,
  Grid,
  Center,
  Button,
  Select,
} from '@chakra-ui/react'
import Star from './star'
import StarIcon from './star-icon'
import sanitizeString from '../utils/sanitize-string'
import {
  Pagination,
  usePagination,
  PaginationPage,
  PaginationNext,
  PaginationPrevious,
  PaginationPageGroup,
  PaginationContainer,
  PaginationSeparator,
} from '@ajna/pagination'

const Reviews = ({ reviewList, title, product, reviewsBottomLineList }) => {
  // states

  const [currentReviews, setCurrentReviews] = useState<any[]>([])

  // constants
  const outerLimit = 2;
  const innerLimit = 2;

  // pagination hook
  const {
    pages,
    pagesCount,
    offset,
    currentPage,
    setCurrentPage,
    setIsDisabled,
    isDisabled,
    pageSize,
    setPageSize,
  } = usePagination({
    total: reviewList.length,
    limits: {
      outer: outerLimit,
      inner: innerLimit,
    },
    initialState: {
      pageSize: 10,
      isDisabled: false,
      currentPage: 1,
    },
  })

  // effects
  useEffect(() => {
    console.log('offset: ', offset)
    console.log('offset: ', offset)
    setCurrentReviews(reviewList.slice(offset, offset + pageSize))
  }, [currentPage, pageSize, offset])

  // handlers
  const handlePageChange = (nextPage: number): void => {
    // -> request new data using the page number
    setCurrentPage(nextPage)
  }

  const handlePageSizeChange = (
    event: ChangeEvent<HTMLSelectElement>
  ): void => {
    const pageSize = Number(event.target.value)

    setPageSize(pageSize)
  }

  // const handleDisableClick = (): void => {
  //   setIsDisabled((oldState) => !oldState);
  // };

  return (
    <Stack>
      {reviewList.length > 0 && (
        <Box py={[8, 12, 12]} id="customer_reviews">
          <Flex flexWrap="wrap">
            <Heading as="h4" mr={2}>{`${title}`}</Heading>
            {reviewsBottomLineList.map((bottomLine: string) => (
              <Star bottomLine={bottomLine} structuredData={true}/>
            ))}
          </Flex>
          {currentReviews.map((review, index) => (
            <Box key={`${index}-yotrev`} mt={4} mb={2} itemprop="review" itemtype="https://schema.org/Review" itemscope="true">
              <Box display="flex" alignItems="center" >
                <Badge colorScheme="brand.palepink" mr={2} itemprop="author" itemscope="true" itemtype="https://schema.org/Person">
                  <span itemprop="name">{review.name}</span>
                </Badge>
                <Box display="flex" alignItems="baseline" itemprop="reviewRating" itemtype="https://schema.org/Rating" itemscope="true">
                  <meta itemprop="worstRating" content="1" />
                  <meta itemprop="ratingValue" content={review.score} />
                  <meta itemprop="bestRating" content="5" />
                  {Array(5)
                    .fill('')
                    .map((_, i) => (
                      <StarIcon review={review} i={i} />
                    ))}
                </Box>
              </Box>

              <Text itemprop="reviewBody">{sanitizeString(review.content)}</Text>
            </Box>
          ))}
        </Box>
      )}

      <Pagination
        pagesCount={pagesCount}
        currentPage={currentPage}
        isDisabled={isDisabled}
        onPageChange={handlePageChange}
      >
        <PaginationContainer
          align="center"
          justify="space-between"
          p={4}
          w="full"
        >
          <PaginationPrevious
            _hover={{
              bg: 'brand.brown.500',
            }}
            bg="brand.brown.400"
          >
            <Text>Previous</Text>
          </PaginationPrevious>
          <PaginationPageGroup
            isInline
            align="center"
            separator={
              <PaginationSeparator
                isDisabled
                bg="blue.300"
                fontSize="sm"
                w={7}
                jumpSize={11}
              />
            }
          >
            {pages.map((page: number) => (
              <PaginationPage
                w={7}
                bg="brand.fish.500"
                key={`pagination_page_${page}`}
                page={page}
                fontSize="sm"
                _hover={{
                  bg: 'brand.green.400',
                }}
                _current={{
                  bg: 'brand.green.400',
                  fontSize: 'sm',
                  w: 7,
                }}
              />
            ))}
          </PaginationPageGroup>
          <PaginationNext
            _hover={{
              bg: 'brand.brown.500',
            }}
            bg="brand.brown.400"
          >
            <Text>Next</Text>
          </PaginationNext>
        </PaginationContainer>
      </Pagination>
      <Center w="full">
        <Select ml={3} onChange={handlePageSizeChange} w={40}>
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
        </Select>
      </Center>
    </Stack>
  )
}

export default Reviews

export const query = graphql`
  fragment YotpoProductReviewConnectionFragment on YotpoProductReviewConnection {
    nodes {
      email
      name
      score
      content
      title
      productIdentifier
    }
  }
`
