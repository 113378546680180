import React, { useState } from 'react'
import {
  Text,
  SimpleGrid,
  FormControl,
  Input,
  useToast,
  Button,
} from '@chakra-ui/react'

const BackInStock = ({ variantId }) => {
  const [email, setEmail] = useState('')
  const [error, setError] = useState('')
  const [previousError, setPreviousError] = useState('')
  const [loading, setLoading] = useState()
  const [processingMessage, setProcessingMessage] = useState('')
  const toast = useToast()
  const formToastId = 'form-warning-toast'

  function wait(ms = 0) {
    return new Promise((resolve, reject) => {
      setTimeout(resolve, ms)
    })
  }

  async function handleSubmit(event) {
    event.preventDefault()
    setLoading(true)
    setProcessingMessage('Sending')

    var requestOptions = {
      method: 'POST',
      headers: {
        accept: 'application/json',
        revision: '2023-10-15',
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        data: {
          type: 'back-in-stock-subscription',
          attributes: {
            profile: {
              data: {
                type: 'profile',
                attributes: {
                  email: email,
                },
              },
            },
            channels: ['EMAIL'],
          },
          relationships: {
            variant: {
              data: {
                type: 'catalog-variant',
                id: '$shopify:::$default:::' + variantId,
              },
            },
          },
        },
      }),
    }

    await fetch(
      'https://a.klaviyo.com/client/back-in-stock-subscriptions/?company_id=Ykscnm',
      requestOptions
    )
      .then(async (json) => {
        setProcessingMessage('Success!')
        await wait(2000)
        setLoading(false)
      })
      .catch((err) => {
        console.error('error:' + err)
        showToast(error)
      })
  }

  function showToast(error) {
    if (!toast.isActive(formToastId)) {
      toast({
        id: formToastId,
        title: 'Something went wrong.',
        description: error,
        status: 'error',
        isClosable: true,
      })
    }
    setPreviousError(error)
  }

  return (
    <form onSubmit={handleSubmit}>
      <SimpleGrid
        columns={[1, 2, 2]}
        minChildWidth="120px"
        alignItems="flex-end"
      >
        <FormControl>
          <Input
            variant="filled"
            type="email"
            name="email"
            id="k_id_email"
            placeholder="your@email.com"
            borderRadius="0"
            onBlur={(event) => setEmail(event.target.value)}
            border={'2px'}
            borderColor={'brand.asparagus.500'}
            background={'white.400'}
            _placeholder={{ opacity: 1, color: 'gray.500' }}
            required
          />
        </FormControl>

        {error !== previousError && showToast(error)}
        <Button type="submit" pr={4} pl={4} colorScheme="brand.green">
          <Text>{loading ? processingMessage : 'Remind me'}</Text>
        </Button>
      </SimpleGrid>
    </form>
  )
}

export default BackInStock
