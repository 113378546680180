import * as React from 'react'
import { useColorModeValue, Icon } from '@chakra-ui/react'
import { BsStarFill } from 'react-icons/bs'

const StarIcon = ({ review, i }) => {
  const starColour = useColorModeValue(`brand.taupe.100`, `brand.dark_gray.500`)
  const starColourEmpty = useColorModeValue(
    `brand.orange.500`,
    `brand.dark_gold.500`
  )
  return (
    <Icon
      as={
        i + 1 <= review.score
          ? BsStarFill
          : i + 0.8 < review.score
          ? BsStarFill
          : i >= review.score
          ? BsStarFill
          : BsStarFill
      }
      key={'review2_' + i}
      fill={i < review.score ? starColourEmpty : starColour}
    />
  )
}

export default StarIcon
