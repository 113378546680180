import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { graphql, navigate } from 'gatsby'
import {
  Container,
  SimpleGrid,
  Grid,
  GridItem,
  Heading,
  Text,
  Box,
  Stack,
  Flex,
  useColorModeValue,
  Select,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useMediaQuery,
  Icon,
  UnorderedList,
  OrderedList,
  ListItem,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react'
import isEqual from 'lodash.isequal'
import Layout from '../components/layout'
import { StoreContext } from '../context/store-context'
import AddToCart from '../components/add-to-cart'
import formatPrice from '../utils/format-price'
import ProductListing from '../components/product-listing'
import Seo from '../components/seo'
import rehypeReact from 'rehype-react'
import { FlatBorder } from '../components/funky-border'
import HorizontalRule from '../components/horizontal-rule'
import ImageFrag from '../components/image-frag'
import Star from '../components/star'
import InfoIcons from '../components/info-icons'
import BackInStock from '../components/back-in-stock'
import { FiX, FiChevronDown } from 'react-icons/fi'
import Slider from 'react-slick'
import { productStructuredData } from '../components/meta/structured-data'
import { PointsForProduct } from '../components/loyaltylion/points'
import Reviews from '../components/reviews'
import borderColor from '../utils/border-color'
import AwardIcons from '../components/award-icons'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const TitleTabs = () => {
  return (
    <>
      <TabList mt="1rem">
        <Tab
          _focus={{
            boxShadow: `none`,
          }}
          borderBottom="4px solid black"
          justifyContent="flex-start"
          pl={0}
        >
          <Text fontWeight="bold" sx={{ whiteSpace: 'nowrap' }}>
            Ingredients <Icon as={FiChevronDown} />
          </Text>
        </Tab>
        <Tab
          _focus={{
            boxShadow: `none`,
          }}
          borderBottom="4px solid black"
          justifyContent="flex-start"
          pl={0}
        >
          <Text fontWeight="bold" sx={{ whiteSpace: 'nowrap' }}>
            Nutrition <Icon as={FiChevronDown} />
          </Text>
        </Tab>
        <Tab
          _focus={{
            boxShadow: `none`,
          }}
          borderBottom="4px solid black"
          justifyContent="flex-start"
          pl={0}
        >
          <Text fontWeight="bold" sx={{ whiteSpace: 'nowrap' }}>
            Instructions <Icon as={FiChevronDown} />
          </Text>
        </Tab>
        <Tab
          _focus={{
            boxShadow: `none`,
          }}
          borderBottom="none"
          justifyContent="flex-end"
          flex="0"
        >
          <Icon display="block" aria-label="Find us on facebook" as={FiX} />
        </Tab>
      </TabList>
    </>
  )
}

const NutritionalTable = ({ children }) => {
  return (
    <Table variant="simple" size="sm">
      {children}
    </Table>
  )
}

const Paragraph = ({ children }) => {
  return (
    <Text mt={4} mb={4}>
      {children}
    </Text>
  )
}

const Panels = ({ children }) => {
  return <TabPanels>{children}</TabPanels>
}

const H4 = ({ children }) => {
  return (
    <Heading as="h4" size={4} mt={2}>
      {children}
    </Heading>
  )
}
const H6 = ({ children }) => {
  return (
    <Text fontWeight="bold" fontStyle="italic" size={1}>
      {children}
    </Text>
  )
}

const MoreInfoAccordion = ({ children }) => {
  return (
    <Accordion allowToggle my={4}>
      <AccordionItem borderTop={0} borderBottom={0}>
        <h4>
          <AccordionButton
            _focus={{
              boxShadow: `none`,
            }}
            display="inline"
            pr={0}
            pl={0}
            textAlign="left"
          >
            <Box display="inline-block" textAlign="left" fontWeight="bold">
              Chief Pie Maker says
            </Box>
            <AccordionIcon display="inline-block" textAlign="left" />
          </AccordionButton>
        </h4>
        <AccordionPanel pb={4}>{children}</AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}

const TestimonialAccordion = ({ children }) => {
  return (
    <Accordion allowToggle my={4}>
      <AccordionItem borderTop={0} borderBottom={0}>
        <Text>
          <AccordionButton
            _focus={{
              boxShadow: `none`,
            }}
            display="inline"
            pr={0}
            pl={0}
            textAlign="left"
          >
            <Box display="inline-block" textAlign="left" fontWeight="bold">
              Mud fans say
            </Box>
            <AccordionIcon display="inline-block" textAlign="left" />
          </AccordionButton>
        </Text>
        <AccordionPanel pb={4}>{children}</AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    p: Paragraph,
    section: TabPanel,
    div: Panels,
    object: TitleTabs,
    table: NutritionalTable,
    thead: Thead,
    tbody: Tbody,
    tr: Tr,
    th: Th,
    td: Td,
    ul: UnorderedList,
    ol: OrderedList,
    li: ListItem,
    h4: H4,
    aside: MoreInfoAccordion,
    dfn: TestimonialAccordion,
    big: H6,
  },
}).Compiler

const Product = ({
  data: { product, suggestions, reviews, reviewsBottomLine, rehype },
  pageContext: { productType, breadcrumb },
}) => {
  const bgGradient = useColorModeValue(`bg`, `dark.bg`)

  const bgInput = useColorModeValue(`white`, `gray.800`)
  const priceColor = useColorModeValue(`primary`, `dark.primary`)

  const {
    options,
    variants,
    variants: [initialVariant],
    priceRangeV2,
    title,
    handle,
    tags,
    media,
    shopifyId,
  } = product

  const { client } = React.useContext(StoreContext)
  const [variant, setVariant] = React.useState({ ...initialVariant })
  const [quantity, setQuantity] = React.useState(1)
  const [isSmallerThan1024] = useMediaQuery('(max-width: 1024px)')
  const [isLargerThan640] = useMediaQuery('(min-width: 640px)')
  const [nav1, setNav1] = useState(null)
  const [nav2, setNav2] = useState(null)
  const [slider1, setSlider1] = useState(null)
  const [slider2, setSlider2] = useState(null)

  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: media.length > 1 ? 0 : 1,
    arrows: true,
    fade: true,
    asNavFor: '.slider-nav',
    adaptiveHeight: true,
  }
  const settingsMainMobile = {
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: media.length > 1 ? 0 : 1,
    arrows: true,
    fade: true,
    asNavFor: '.slider-nav',
    preventDefaultSwipeY: false,
    swipeToSlide: false,
    draggable: false,
    mobileFirst: true,
    swipe: false,
    touchMove: false,
    adaptiveHeight: true,
  }

  const settingsThumbs = {
    slidesToShow: 2,
    //slidesToShow: images.length,
    initialSlide: 1,
    slidesToScroll: 1,
    arrows: false,
    asNavFor: '.slider-for',
    dots: false,
    swipeToSlide: false,
    focusOnSelect: true,
  }

  const defaultBorderColour = borderColor(tags)
  // console.log(defaultBorderColour)
  const productVariant =
    client.product.helpers.variantForOptions(product, variant) || variant

  const [available, setAvailable] = React.useState(
    productVariant.availableForSale
  )

  const checkAvailablity = React.useCallback(
    (productId) => {
      client.product.fetch(productId).then((fetchedProduct) => {
        const result = fetchedProduct.variants.filter(
          (variant) => variant.id === productVariant.storefrontId
        )

        if (result.length > 0) {
          setAvailable(result[0].available)
        }
      })
    },
    [productVariant.storefrontId, client.product]
  )

  const handleOptionChange = (index, event) => {
    const value = event.target.value

    if (value === '') {
      return
    }

    const currentOptions = [...variant.selectedOptions]

    currentOptions[index] = {
      ...currentOptions[index],
      value,
    }

    const selectedVariant = variants.find((variant) => {
      return isEqual(currentOptions, variant.selectedOptions)
    })

    setVariant({ ...selectedVariant })
  }

  // const trackProductView = () => {
  //   if (typeof omnisend === `function`) {

  //     omnisend.push(["track", "productViewed",{ // eslint-disable-line no-undef
  //       $productID: product.id,
  //       $variantID: product.variantId,
  //       $currency: "GBP",
  //       $price: variant.price, //price in cents (integer)
  //       // $oldPrice: 6999, //old price in cents (integer)
  //       $title: product.seo.title,
  //       $description: product.seo.description,
  //       $imageUrl: firstImage.localFile.publicURL,
  //       $productUrl:product.url,
  //       $vendor: product.vendor
  //      }])
  //   }
  // }

  let firstReview = {
    content: '',
    email: '',
    name: '',
    productIdentifier: '',
    score: 5,
    title: '',
  }

  let firstBottomLine = {
    score: 5,
    totalReviews: 0,
  }

  let current = new Date()
  var startYear = new Date(
    current.getFullYear() + 1,
    current.getMonth(),
    current.getDate()
  )
  var priceValidUntilDate = startYear.toLocaleString('en-GB')

  function sanitizeString(str) {
    str = str.replace('&#x27;', '’')
    str = str.replace(/[^a-z0-9áéíóúñü .,’"_-]/gim, ' ')
    return str.trim()
  }

  function transformYoutuDotBeEmbedUrlToIframeEmbedUrl(shortenedUrl) {
    // Youtube shortened URL to iframe embed URL transformation logic
    // Example input: https://youtu.be/dQw4w9WgXcQ
    // Example output: https://www.youtube.com/embed/dQw4w9WgXcQ

    const youtubeRegex =
      /^(?:https?:\/\/)?(?:www\.)?youtu\.?be(?:\.com)?\/?.*(?:watch|embed)?(?:.*v=|v\/|\/)([\w\-_]+)/
    const match = shortenedUrl.match(youtubeRegex)

    if (match) {
      return `https://www.youtube.com/embed/${match[1]}`
    }

    return shortenedUrl
  }

  if (reviews.nodes.length > 0) {
    firstReview = reviews.nodes[0]
    firstReview.content = sanitizeString(firstReview.content)
  }

  if (reviewsBottomLine.nodes.length > 0) {
    firstBottomLine = reviewsBottomLine.nodes[0]
  }

  const viewedProductEvent = () => {
    if (
      process.env.NODE_ENV === `production` &&
      // prettier-ignore
      typeof _learnq.push === `function` // eslint-disable-line no-undef
    ) {
      var item = {
        Name: title,
        ProductID: handle,
        Categories: productType,
        ImageURL: media[0].image.src,
        URL: 'https://mudfoods.com/products/' + handle,
        Price: variant.price,
      }

      // prettier-ignore
      _learnq.push(['track', 'Viewed Product', item]) // eslint-disable-line no-undef
      // prettier-ignore
      _learnq.push([// eslint-disable-line no-undef
        'trackViewedItem',
        {
          Title: title,
          ItemId: handle,
          Categories: productType,
          ImageUrl: media[0].image.src,
          Url: 'https://mudfoods.com/products/' + handle,
          Metadata: {
            Price: variant.price,
          },
        },
      ])
    }
  }

  useEffect(() => {
    setTimeout(() => viewedProductEvent(), 3000)
  })

  useEffect(() => {
    checkAvailablity(product.storefrontId)

    // trackProductView()
    setNav1(slider1)
    setNav2(slider2)
  }, [
    productVariant.storefrontId,
    checkAvailablity,
    product.storefrontId,
    slider1,
    slider2,
    media[0].image.src,
    media,
    handle,
    productType,
    title,
    variant.price,
  ])

  const price = formatPrice(
    priceRangeV2.minVariantPrice.currencyCode,
    variant.price
  )

  const hasVariants = variants.length > 1
  const hasMultipleImages = media.length > 1

  const [borderColourScheme, setBorderColorScheme] =
    useState(defaultBorderColour)

  const variantGid = productVariant.storefrontId.substring(
    'gid://shopify/ProductVariant/'.length
  )

  //Sort product images (ino is something defined by Shopify thats appears to correspond to placement in backend)
  product.media.sort((a, b) => {
    if (b.image !== undefined && a.image !== undefined)
      return b.image.localFile.ino - a.image.localFile.ino
  })

  return (
    <Layout breadcrumbs={breadcrumb}>
      <Seo
        title={product.seo.title || product.title}
        description={product.seo.description && product.seo.description}
        image={media[0].image}
      />
      <Helmet>
        <script type="application/ld+json">
          {productStructuredData({
            name: product.title,
            URL: 'https://mudfoods.com/products/' + product.handle,
            imageUrl: media[0].image.src,
            sku: variant.sku,
            gtin: variant.barcode,
            description:
              product.description.replace(/^(.{200}[^\s]*).*/, '$1') + '...',
            price: variant.price,
            priceCurrency: 'GBP',
            priceValidUntil: priceValidUntilDate,
            availability: available
              ? 'https://schema.org/InStock'
              : 'https://schema.org/OutOfStock',
            reviewer: firstReview.name,
            reviewDate: firstReview.reviewDate,
            reviewBody: firstReview.title + ' | ' + firstReview.content,
            reviewRating: firstReview.score,
            aggregateRating: firstBottomLine.score,
            numberOfRatings: firstBottomLine.totalReviews,
          })}
        </script>
      </Helmet>
      <Box bgGradient={bgGradient}>
        <Container
          py={[8, 12, 12]}
          itemprop="mainEntity"
          itemscope="true"
          itemtype="https://schema.org/Product"
        >
          <SimpleGrid
            columns={[1, 1, 2]}
            spacingX="40px"
            spacingY="20px"
            mb={6}
            // templateColumns={['1fr', null, 'repeat(2, 1fr)']}
            // gap={[12, 20]}
            // sx={{
            //   '[data-name="product-image-wrapper"]': { order: [1, null, 2] },
            // }}
          >
            <Stack spacing={[4, 6]} order={[2, null, 2]}>
              <Stack spacing={4}>
                <Flex flexWrap="wrap">
                  <Heading as="h1" mr={2} itemprop="name">
                    {title}
                  </Heading>
                  <meta
                    itemprop="url"
                    content={'https://mudfoods.com/products/' + product.handle}
                  />
                  <meta
                    itemprop="description"
                    content={product.seo.description}
                  />
                  <meta itemprop="image" content={media[0].image.src} />
                  <meta itemprop="gtin14" content={variant.barcode} />
                  {reviewsBottomLine.nodes.map((bottomLine, index) => (
                    <Star
                      bottomLine={bottomLine}
                      key={'BottomLines_' + index + '_2'}
                    />
                  ))}
                </Flex>

                {!available && (
                  <Box backgroundColor={'brand.orange.50'} padding={'1rem'}>
                    <Text fontWeight="bold" fontSize="large">
                      This product is currently unavailable, don't panic!
                    </Text>
                    <Text mb={1}>
                      We've got you covered, input your email and we'll notify
                      you when it's back
                    </Text>
                    <BackInStock variantId={variantGid} />
                  </Box>
                )}

                {/* <Text>{renderAst(productDetails)}</Text> */}
                <Tabs
                  colorScheme="brand.gray"
                  size="md"
                  defaultIndex="4"
                  isFitted
                >
                  <Flex mr={2} ml={2} direction="row" float="right">
                    <InfoIcons tags={tags} boxSize={[8]} />
                  </Flex>
                  {/* 
                  {tags.includes('bundle') ? (
                    <Container
                      dangerouslySetInnerHTML={{ __html: descriptionHtml }}
                    />
                  ) : ( */}

                  {rehype.edges.length > 0
                    ? renderAst(rehype.edges[0].node.htmlAst)
                    : ''}
                </Tabs>
              </Stack>
              <Stack spacing={0}>
                <Grid
                  gap={4}
                  templateColumns={['repeat(4, 1fr)']}
                  as="form"
                  noValidate
                  direction={['row']}
                  flexWrap="wrap"
                  justifyItems="inherit"
                >
                  {hasVariants && (
                    <>
                      {/* <GridItem colSpan={4}>
                        <Text
                          fontSize="2xl"
                          fontWeight="bold"
                          color={priceColor}
                        >
                          {`${options[0].values[0]} - ${
                            options[0].values[options[0].values.length - 1]
                          }`}
                        </Text>
                      </GridItem> */}
                      <GridItem colSpan={[4, 4, 4, 2]}>
                        {options.map(({ id, name, values }, index) => (
                          <React.Fragment key={id}>
                            <Stack as="fieldset" mt={4} mr={6}>
                              <label htmlFor="variant">{name}</label>
                              <Select
                                bg={bgInput}
                                onChange={(event) =>
                                  handleOptionChange(index, event)
                                }
                              >
                                {/* <option value="">{`Choose ${name}`}</option> */}
                                {values.map((value) => (
                                  <option
                                    value={value}
                                    key={`${name}-${value}`}
                                  >
                                    {value}
                                  </option>
                                ))}
                              </Select>
                            </Stack>
                          </React.Fragment>
                        ))}
                      </GridItem>
                    </>
                  )}

                  {/* <GridItem
                    colSpan={hasVariants ? [2, 2, 2, 1] : [2, 2, 2, 2]}
                    alignSelf="end"
                    maxW={20}
                  >
                    <QuantityInput
                      quantity={quantity}
                      setQuantity={setQuantity}
                    />
                  </GridItem> */}

                  <GridItem
                    colSpan={[2, 2, 4, 4]}
                    alignSelf="flex-end"
                    justifySelf="flex-end"
                  >
                    <Text
                      fontSize="2xl"
                      fontWeight="bold"
                      color={priceColor}
                      itemprop="offers"
                      itemscope
                      itemtype="https://schema.org/Offer"
                    >
                      <span
                        id="ProductPrice"
                        data-lion-price-for-product-id={shopifyId.replace(
                          /[^0-9]/g,
                          ''
                        )}
                      >
                        {price}
                      </span>
                      <meta
                        itemprop="price"
                        content={
                          productVariant.presentmentPrices[0].price.amount
                        }
                      />
                      <meta
                        itemprop="priceCurrency"
                        content={
                          productVariant.presentmentPrices[0].price.currencyCode
                        }
                      />
                      <link
                        itemprop="availability"
                        href="https://schema.org/InStock"
                      />

                      {productVariant.presentmentPrices[0].compareAtPrice &&
                        ' / '}
                      {productVariant.presentmentPrices[0].compareAtPrice && (
                        <Text as="del">
                          {
                            productVariant.presentmentPrices[0].compareAtPrice
                              .amount
                          }
                        </Text>
                      )}
                    </Text>
                    <PointsForProduct shopifyId={shopifyId} />
                  </GridItem>

                  {!isSmallerThan1024 && (
                    <GridItem colSpan={2} alignSelf="end">
                      {' '}
                      <Button
                        w="100%"
                        variant="outline"
                        alignSelf="flex-end"
                        onClick={() => navigate('/')}
                        colorScheme="brand.gray"
                      >
                        Continue shopping
                      </Button>
                    </GridItem>
                  )}
                  <GridItem colSpan={[4, 4, 4, 2]}>
                    <AddToCart
                      type="button"
                      colorScheme={defaultBorderColour}
                      variantId={productVariant.storefrontId}
                      product={product}
                      quantity={quantity}
                      available={available}
                      alignSelf="flex-end"
                      boxShadow="md"
                    />
                  </GridItem>
                </Grid>
              </Stack>
            </Stack>

            {hasMultipleImages ? (
              <Box
                data-name="product-image-wrapper"
                order={[1, null, 1]}
                maxW="100%"
              >
                <Box className="thumbnail-slider-wrap">
                  {isLargerThan640 ? (
                    <Slider
                      {...settingsMain}
                      asNavFor={nav2}
                      ref={(slider) => setSlider1(slider)}
                    >
                      {product.media.map((media, index) =>
                        media.mediaContentType === 'IMAGE'
                          ? media.image && (
                              <Box>
                                <ImageFrag
                                  imageData={media.image}
                                  key={`product-image-${index}`}
                                  alt={product.title}
                                  // loading={index != 0 ? 'lazy' : 'eager'}
                                />
                                <Box
                                  position={'absolute'}
                                  width={'100%'}
                                  padding={'9px'}
                                  bottom={'0'}
                                >
                                  <Flex
                                    justifyContent={'flex-end'}
                                    width={'100%'}
                                    gap={1}
                                  >
                                    <AwardIcons
                                      tags={tags}
                                      boxSize={[20, 20, 20, 24]}
                                    />
                                  </Flex>
                                </Box>
                              </Box>
                            )
                          : media.host === 'YOUTUBE' && (
                              <Box key={`product-image-${index}`}>
                                <iframe
                                  width="560"
                                  height="315"
                                  src={transformYoutuDotBeEmbedUrlToIframeEmbedUrl(
                                    media.embeddedUrl
                                  )}
                                  title="YouTube video player"
                                  frameborder="0"
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                  allowfullscreen
                                ></iframe>
                              </Box>
                            )
                      )}
                    </Slider>
                  ) : (
                    <Slider
                      {...settingsMainMobile}
                      asNavFor={nav2}
                      ref={(slider) => setSlider1(slider)}
                    >
                      {product.media.map((media, index) =>
                        media.mediaContentType === 'IMAGE'
                          ? media.image && (
                              <Box>
                                <ImageFrag
                                  imageData={media.image}
                                  key={`product-image-${index}`}
                                  alt={product.title}
                                  // loading={'eager'}
                                />
                                <Box
                                  position={'absolute'}
                                  width={'100%'}
                                  padding={'9px'}
                                  bottom={'0'}
                                >
                                  <Flex
                                    justifyContent={'flex-end'}
                                    width={'100%'}
                                    gap={1}
                                  >
                                    <AwardIcons
                                      tags={tags}
                                      boxSize={[20, 20, 20, 24]}
                                    />
                                  </Flex>
                                </Box>
                              </Box>
                            )
                          : media.host === 'YOUTUBE' && (
                              <Box key={`product-image-${index}`}>
                                <iframe
                                  width="560"
                                  height="315"
                                  src={transformYoutuDotBeEmbedUrlToIframeEmbedUrl(
                                    media.embeddedUrl
                                  )}
                                  title="YouTube video player"
                                  frameborder="0"
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                  allowfullscreen
                                ></iframe>
                              </Box>
                            )
                      )}
                    </Slider>
                  )}
                </Box>

                {isLargerThan640 && (
                  <Slider
                    {...settingsThumbs}
                    asNavFor={nav1}
                    ref={(slider) => setSlider2(slider)}
                  >
                    {product.media.map((media, index) =>
                      media.mediaContentType === 'IMAGE' ? (
                        media.image && (
                          <Flex
                            flexDirection={'column'}
                            key={`thumbnailx-${index}`}
                          >
                            <ImageFrag
                              imageData={media.image}
                              key={`product-image-thumbx-${index}`}
                              alt={media.image.altText}
                            />
                            <FlatBorder
                              colorScheme={borderColourScheme}
                              tags={tags}
                              thickness={1}
                            />
                          </Flex>
                        )
                      ) : (
                        <Box key={`thumbnail-${index}`}>
                          <ImageFrag
                            imageData={media.preview.image}
                            alt={product.title}
                            // loading="lazy"
                          />

                          <FlatBorder
                            colorScheme={borderColourScheme}
                            tags={tags}
                            thickness={1}
                          />
                        </Box>
                      )
                    )}
                  </Slider>
                )}
              </Box>
            ) : (
              //Less than 2 images
              <Box
                data-name="product-image-wrapper"
                order={[1, null, 1]}
                maxW="100%"
              >
                <Box className="thumbnail-slider-wrap">
                  {product.media.map(
                    (media, index) =>
                      media.image && (
                        <Flex flexDirection={'column'}>
                          <ImageFrag
                            imageData={media.image}
                            key={`product-image-thumb-${index}`}
                            alt={media.image.altText}
                            // loading={index != 0 ? 'lazy' : 'eager'}
                          />
                          <FlatBorder
                            colorScheme={borderColourScheme}
                            tags={tags}
                            thickness={1}
                          />
                        </Flex>
                      )
                  )}
                </Box>
              </Box>
            )}
          </SimpleGrid>
          {suggestions && suggestions.nodes.length > 0 && (
            <>
              <HorizontalRule />
              <Box my={[16]}>
                <Text mb={8} fontSize="3xl">
                  Pairs beautifully with...
                </Text>
                <ProductListing products={suggestions.nodes} maximum={3} />
              </Box>
            </>
          )}
          {reviews.nodes.length > 0 && <HorizontalRule />}
          {reviews.nodes.length > 0 && (
            <Reviews
              reviewList={reviews.nodes}
              title={`${product.title} Customer Reviews`}
              reviewsBottomLineList={reviewsBottomLine.nodes}
              product={product}
            />
          )}
        </Container>
      </Box>
    </Layout>
  )
}

export default Product

export const query = graphql`
  query ($id: String!, $recommededProductType: [String!], $gid: String!) {
    product: shopifyProduct(id: { eq: $id }) {
      title
      description
      descriptionHtml
      shopifyId
      onlineStoreUrl
      handle
      seo {
        title
        description
      }

      tags
      priceRangeV2 {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
      storefrontId
      media {
        mediaContentType
        ... on ShopifyMediaImage {
          image {
            ...ShopifyMediaImageFragment
          }
        }
        ... on ShopifyExternalVideo {
          id
          embeddedUrl
          host
          alt
          preview {
            image {
              ...ShopifyMediaImageFragment
            }
          }
        }
      }
      variants {
        media {
          ... on ShopifyMediaImage {
            image {
              ...ShopifyMediaImageFragment
            }
          }
        }
        availableForSale
        storefrontId
        title
        barcode
        price
        presentmentPrices {
          price {
            amount
            currencyCode
          }
          compareAtPrice {
            amount
            currencyCode
          }
        }
        sku
        selectedOptions {
          name
          value
        }
      }
      options {
        name
        values
        shopifyId
      }
    }
    reviewsBottomLine: allYotpoProductBottomline(
      filter: { productIdentifier: { eq: $gid } }
    ) {
      nodes {
        score
        totalReviews
      }
    }
    reviews: allYotpoProductReview(
      limit: 500
      sort: { fields: sentiment, order: DESC }
      filter: { productIdentifier: { eq: $gid } }
    ) {
      nodes {
        email
        name
        score
        content
        title
        productIdentifier
      }
    }
    suggestions: allShopifyProduct(
      limit: 3
      filter: {
        productType: { in: $recommededProductType }
        id: { ne: $id }
        status: { eq: ACTIVE }
        hasOutOfStockVariants: { eq: false }
      }
      sort: { fields: metafields___updatedAt, order: ASC }
    ) {
      nodes {
        ...ProductCard
      }
    }
    rehype: allHtmlRehype(filter: { parent: { id: { eq: $id } } }) {
      edges {
        node {
          id
          htmlAst
        }
      }
    }
  }
`
